import DefaultBrand from '../assets/svg-icons/app-logo-canal.svg';
import canalgroupEn from '../assets/svg-icons/canal-group.svg';
import CloseIcon from '../assets/icons/cross.svg';
import Dailymotion from '../assets/svg-icons/dailymotion.svg';
import Facebook from '../assets/svg-icons/facebook.svg';
import Twitter from '../assets/svg-icons/twitter.svg';
import Linkedin from '../assets/svg-icons/linkedin.svg';
import canalGroup from '../assets/svg-icons/canal-groupe.svg';

const BRANDS = {
  canalGroup,
  canalgroupEn,
  CloseIcon,
  Dailymotion,
  Facebook,
  Twitter,
  Linkedin,
};

export const getBrandLogoUrl = (brand: string) => BRANDS[brand] || DefaultBrand;
